import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prestador } from "@/app/interfaces/interfaces"; 

interface PrestadoresState {
  prestadores: Prestador[];
  filteredDataUser: Prestador[];
  filteredDataEspecialidad: Prestador[];
  filtrosAplicados: boolean; 
 
  
}

const initialState: PrestadoresState = {
  prestadores: [],
  filteredDataUser: [],
  filteredDataEspecialidad: [],
  filtrosAplicados:false,
};

const prestadoresSlice = createSlice({
  name: 'prestadores',
  initialState,
  reducers: {
    setPrestadores(state, action: PayloadAction<Prestador[]>) {
      state.prestadores = action.payload;
    },
    setFilteredDataUser(state, action: PayloadAction<Prestador[]>) {
      state.filteredDataUser = action.payload;
      state.filtrosAplicados = action.payload.length > 0 || state.filteredDataEspecialidad.length > 0;
    },
    setFilteredDataEspecialidad(state, action: PayloadAction<Prestador[]>) {
      state.filteredDataEspecialidad = action.payload;
      state.filtrosAplicados = state.filteredDataUser.length > 0 || action.payload.length > 0;
    },
    
  },
});

export const { setPrestadores, setFilteredDataUser, setFilteredDataEspecialidad } = prestadoresSlice.actions;

export default prestadoresSlice.reducer;
