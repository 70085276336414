import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavbarState {
  activeButton: boolean; 
  mostrarDelegacion:boolean,
  servicioSeleccionado:string,
 
}

const initialState: NavbarState = {
  activeButton: false,
  mostrarDelegacion: false,
  servicioSeleccionado:"",
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setActiveButton(state, action: PayloadAction<boolean >) {
      state.activeButton = action.payload;
    },
    setMostrarDelegacion(state, action:PayloadAction<boolean >)  {
     
      state.mostrarDelegacion = action.payload; 
    },
   
    setServicioSeleccionado(state, action: PayloadAction<string >) {
      state.servicioSeleccionado = action.payload;
      //console.log("ServicioSeleccionado action dispatched with payload:", action.payload);
    },

  },
});

export const { 
  setActiveButton,
  setMostrarDelegacion,
  setServicioSeleccionado,
} = navbarSlice.actions;

export default navbarSlice.reducer;
