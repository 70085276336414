import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\@next\\third-parties\\dist\\google\\ga.js");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\@next\\third-parties\\dist\\google\\gtm.js");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\@next\\third-parties\\dist\\ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\bootstrap\\dist\\css\\bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\layout.tsx\",\"import\":\"Ubuntu\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"ubuntu\"}");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\globals.css");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\primereact\\resources\\themes\\saga-blue\\theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\ReduxWrapper.tsx");
