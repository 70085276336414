import { configureStore } from '@reduxjs/toolkit';
import navbarReducer from '@/app/redux/Slice/navbarSlice';
import loadingReducer from '@/app/redux/Slice/loading';
import filterReducer from   '@/app/redux/Slice/filter';

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    loading:loadingReducer,
    filter:filterReducer

  },
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;




