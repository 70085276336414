
"use client"; 

import { Provider } from 'react-redux';
import { store } from '@/app/redux/Store/store';

const ReduxWrapper = ({ children }: { children: React.ReactNode }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxWrapper;
